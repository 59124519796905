import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';
import edit from '../assets/svg/edit.svg';

import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>

                </div>
                <div className="items">
                    <h6>POČETNA</h6>
                    <ul>

                        <li>
                            <Link to='/' className={this.props[0].location.pathname == '/' ? 'active' : null}>
                                <Isvg src={home} />
                                Uredi
                            </Link>
                        </li>




                    </ul>
                    <h6>Korisnici</h6>
                    <ul>

                        <li>
                            <Link to='/users' className={this.props[0].location.pathname == '/users' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi korisnici
                            </Link>
                        </li>
                    </ul>

                    {/* <h6>STRANICE</h6>
                    <ul>
                        <li>
                            <Link to='/collection/services' className={this.props[0].location.pathname == '/collection/services' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve stranice
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/services/new' className={this.props[0].location.pathname == '/collection/services/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova stranica
                            </Link>
                        </li>
                    </ul> */}
                    <h6>Izvodjaci</h6>
                    <ul>

                        <li>
                            <Link to='/collection/performers' className={this.props[0].location.pathname == '/collection/performers' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi izvodjaci
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/performers/new' className={this.props[0].location.pathname == '/collection/performers/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi izvodjac
                            </Link>
                        </li>


                    </ul>
                    <h6>Dogadjaji</h6>
                    <ul>

                        <li>
                            <Link to='/collection/events' className={this.props[0].location.pathname == '/collection/events' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi dogadjaji
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/events/new' className={this.props[0].location.pathname == '/collection/events/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi dogadjaj
                            </Link>
                        </li>


                    </ul>
                    <h6>Galerija</h6>
                    <ul>

                        <li>
                            <Link to='/collection/gallery' className={this.props[0].location.pathname == '/collection/gallery' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi albumi
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/gallery/new' className={this.props[0].location.pathname == '/collection/gallery/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi album
                            </Link>
                        </li>



                    </ul>

                    <h6>BLOG</h6>
                    <ul>

                        <li>
                            <Link to='/collection/blog' className={this.props[0].location.pathname == '/collection/blog' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi blogovi
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/blog/new' className={this.props[0].location.pathname == '/collection/blog/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi blog
                            </Link>
                        </li>
                    </ul>
                    <h6>Igrice</h6>
                    <ul>

                        <li>
                            <Link to='/collection/games' className={this.props[0].location.pathname == '/collection/games' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve igrice
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/games/new' className={this.props[0].location.pathname == '/collection/games/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova igrica
                            </Link>
                        </li>


                    </ul>
                    <h6>Stream</h6>
                    <ul>
                        <li>
                            <Link to='/stream' className={this.props[0].location.pathname == '/stream' ? 'active' : null}>
                                <Isvg src={edit} />
                                Uredi
                            </Link>
                        </li>
                    </ul>



                    <h6>LOKACIJE</h6>
                    <ul>

                        <li>
                            <Link to='/collection/locations' className={this.props[0].location.pathname == '/collection/locations' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve lokacije
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/locations/new' className={this.props[0].location.pathname == '/collection/locations/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Nova lokacija
                            </Link>
                        </li>


                    </ul>

                    {/* <h6>VIDEO SNIMCI</h6>
                    <ul>

                        <li>
                            <Link to='/collection/videos' className={this.props[0].location.pathname == '/collection/videos' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi video snimci
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/videos/new' className={this.props[0].location.pathname == '/collection/videos/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi video snimak
                            </Link>
                        </li>


                    </ul>
                    <h6>DOKUMENTI</h6>
                    <ul>

                        <li>
                            <Link to='/collection/documents' className={this.props[0].location.pathname == '/collection/documents' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi dokumenti
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/documents/new' className={this.props[0].location.pathname == '/collection/documents/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Novi dokument
                            </Link>
                        </li>


                    </ul>



                    <h6>KONTAKTI</h6>
                    <ul>
                        <li>
                            <Link to='/contacts' className={this.props[0].location.pathname == '/contacts' ? 'active' : null}>
                                <Isvg src={mail} />
                                Svi kontakti
                            </Link>
                        </li>

                    </ul> */}


                    <h6>SEO</h6>
                    <ul>
                        <li>
                            <Link to='/collection/seo' className={this.props[0].location.pathname == '/collection/seo' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi linkovi
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/seo/new' className={this.props[0].location.pathname == '/collection/seo/new' ? 'active' : null}>
                                <Isvg src={rocket} />
                                Novi link
                            </Link>
                        </li>


                    </ul>


                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('token')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                Odjavi se
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div>

            </div >
        )
    }

};

export default Sidebar;